import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LogoSmall from "../../assets/logo.png";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#11998E",
  color: "#fff",
  padding: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Left = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  textAlign: "left",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

const Right = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
  textAlign: "right",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    textAlign: "center",
  },
}));

const Logo = styled("img")({
  width: "50px",
});

const Link = styled("a")({
  textDecoration: "none",
  color: "#fff",
  "&:hover": {
    textDecoration: "underline",
    color: "#66ccff",
  },
});
const Text = styled(Typography)({
  paddingTop: "4px",
  paddingBottom: "4px",
});

const Footer = () => {
  return (
    <Root>
      <Left>
        <Logo src={LogoSmall} alt="Logo" />
      </Left>
      <Right>
        <Link href="/privacy">
          <Text>Privacy</Text>
        </Link>
        <Text>© 2024 by Soft Spark, All rights reserved</Text>
      </Right>
    </Root>
  );
};

export default Footer;
