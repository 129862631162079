import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import Layout from "./common/Layout";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const GeneralErrorPage = () => {

  const navigateHome = () => {
    window.location.href = "/";
  };

  return (
    <Layout disableContainer={true}>
      <Container>
        <Typography variant="h4">
          Something went wrong!
        </Typography>

        <Typography variant="body1">
          Refresh the page or return home.
        </Typography>

        <Button
          sx={{
            mt: 2,
          }}
          variant="contained"
          color="primary"
          onClick={navigateHome}
        >
          Go home
        </Button>
      </Container>
    </Layout>
  );
};

export default GeneralErrorPage;
