import React from "react";
import "firebase/compat/auth";
import { styled } from "@mui/material/styles";
import { Box, Typography, Container } from "@mui/material";
import TopNavBar from "./TopNavBar";
import PropTypes from "prop-types";
import Footer from "./Footer";

const Text = styled(Typography)(({ theme, hasSubtitle }) => ({
  color: "#fff",
  textAlign: "center",
  padding: theme.spacing(2),
  margin: hasSubtitle ? theme.spacing(4, 0, 0) : theme.spacing(4, 0, 4),
}));

const SubText = styled(Text)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(0, 0, 4),
}));
const FullBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexGrow: 1,
}));

const OverlayBox = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  left: 0,
  height: "120px",
  background:
    "linear-gradient(48deg, #11998E 51%, #38EF7D 100%)",
  zIndex: "-999",
}));

function Layout({
  children,
  title,
  subtitle,
  disableContainer = false,
  responsiveContainer = false,
  showOverlay = false,
}) {
  return (
    <Box
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Box
        style={{
          background:
            "linear-gradient(100deg, #11998E 50%, #38EF7D 100%)",
        }}
      >
        <TopNavBar></TopNavBar>
        {title && (
          <Text variant="h3" hasSubtitle={!!subtitle}>
            {title}
          </Text>
        )}
        <Container maxWidth="md">
          {subtitle && <SubText>{subtitle}</SubText>}
        </Container>
        {showOverlay && <OverlayBox />}
      </Box>
      <FullBox>
        {disableContainer ? (
          children
        ) : (
          <Container
            maxWidth={responsiveContainer ? false : "lg"}
            sx={{ p: 2, height: "100%", flexGrow: 1 }}
          >
            {children}
          </Container>
        )}
      </FullBox>
      <Footer />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disableContainer: PropTypes.bool,
  responsiveContainer: PropTypes.bool,
};

export default Layout;
