import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getAuth } from "@firebase/auth";

import logo from "../../assets/logo.png";
import firebase from "firebase/compat/app";

const TopNavBar = ({ hasBackground = false }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  const menuItems = getMenuItems(user, signout);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (auth) => {
      if(auth) {
        setUser(true);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const handleMenuOpen = (event, dropdownName) => {
    setActiveDropdown(dropdownName);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActiveDropdown(null);
  };

  return (
    <AppBar position="static" style={getAppBarStyle(hasBackground)}>
      <Toolbar sx={{ flexWrap: "nowrap", justifyContent: "space-between" }}>
        <img
          src={logo}
          alt="logo"
          onClick={() => navigate("/")}
          style={getLogoStyle()}
        />
        <Typography style={{ marginLeft: 8 }} variant="h5">Passpoems</Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 1,
            justifyContent: "flex-end",
            flexDirection: ["column", "row"],
            textAlign: ["center", "right"],
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            menuItems
              .filter((item) => !item.role || item.role.includes(user?.role))
              .map((item) =>
                renderMenuItem(
                  item,
                  handleMenuOpen,
                  handleMenuClose,
                  () => setDrawerOpen(false),
                  activeDropdown,
                  anchorEl,
                ),
              )
          )}
        </Box>
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: "70%", color: "#fff" }}
        PaperProps={{
          style: {
            maxWidth: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#001556",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "8px",
            }}
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <List>
            {loading ? (
              <CircularProgress />
            ) : (
              menuItems
                .filter((item) => !item.role || item.role.includes(user?.role))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      onClick={() => handleDrawerItemClick(item)}
                      sx={{
                        color: "#fff",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.name}
                      {item.dropdownItems &&
                        (openDropdowns[item.name] ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        ))}
                    </ListItem>
                    {item.dropdownItems &&
                      openDropdowns[item.name] &&
                      item.dropdownItems.map((subItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          onClick={() => handleDrawerItemClick(subItem)}
                          sx={{
                            color: "#fff",
                            paddingLeft: "2em",
                          }}
                        >
                          {subItem.name}
                        </ListItem>
                      ))}
                  </React.Fragment>
                ))
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );

  async function signout() {
    try {
      await getAuth().signOut();
      navigate("/");
      setUser({});
    } catch (error) {
      console.error(error);
    }
  }

  function handleDrawerItemClick(item) {
    if (item.dropdownItems) {
      setOpenDropdowns((prev) => ({
        ...prev,
        [item.name]: !prev[item.name],
      }));
    } else {
      if (item.action) {
        item.action();
      } else if (item.url) {
        navigate(`/${item.url}`);
      }
      setDrawerOpen(false);
    }
  }
};

const getMenuItems = (user, signout) => {
  const itemsForLoggedInUser = [
    {
      name: "Poems",
      url: "poems",
    },
    { name: "Logout", action: signout },
  ];
  return user ? itemsForLoggedInUser : [];
};

const getAppBarStyle = (hasBackground) => {
  return {
    background: hasBackground
      ? "linear-gradient(100deg, #614385 50%, #516395 100%)" // gradient from https://www.b3multimedia.ie/beautiful-color-gradients-for-your-next-design-project/
      : null,
    backgroundColor: "transparent",
    boxShadow: "none",
  };
};

const getLogoStyle = () => {
  return {
    cursor: "pointer",
    userSelect: "none",
    marginTop: "24px",
    marginBottom: "24px",
    maxHeight: "50px",
    margin: "16px 0px 16px 0px",
  };
};

const renderMenuItem = (
  item,
  handleMenuOpen,
  handleMenuClose,
  closeDrawer,
  activeDropdown,
  anchorEl,
) => {
  const isDropdown = item.dropdownItems && item.dropdownItems.length > 0;

  const handleItemClick = () => {
    if (item.action) {
      item.action();
    }
    closeDrawer();
  };

  return (
    <Box
      key={item.name}
      onMouseEnter={
        isDropdown ? (event) => handleMenuOpen(event, item.name) : null
      }
      onMouseLeave={isDropdown ? handleMenuClose : null}
      sx={{ position: "relative" }}
    >
      <Typography
        variant="h6"
        component={isDropdown ? "div" : Link}
        to={isDropdown ? null : "/" + getUrlFromName(item)}
        onClick={handleItemClick}
        style={{ ...getMenuItemStyle(item), color: "#fff" }}
      >
        {item.name}
      </Typography>
      {isDropdown &&
        renderDropdownMenu(item, handleMenuClose, activeDropdown, anchorEl)}
    </Box>
  );
};

const getUrlFromName = (item) => {
  return item.url ? item.url : item.name.toLowerCase().replace(" ", "-");
};

const getMenuItemStyle = (item) => {
  return {
    fontSize: "1em",
    fontWeight:
      item.url === window.location.pathname.substring(1) ? "bold" : "normal",
    textDecorationLine:
      item.url === window.location.pathname.substring(1) ? "underline" : "none",
    marginLeft: 20,
    marginRight: 20,
    marginTop: [4, 0],
    color: "#fff",
    cursor: item.dropdownItems ? "pointer" : null,
    borderRadius: item.outline ? "25px" : null,
    border: item.outline ? "2px solid white" : null,
    padding: item.outline ? "5px 40px" : null,
  };
};

const renderDropdownMenu = (
  item,
  handleMenuClose,
  activeDropdown,
  anchorEl,
) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={activeDropdown === item.name}
      onClose={handleMenuClose}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      PaperProps={{
        elevation: 3,
        sx: {
          backgroundColor: "#fff",
          borderRadius: "4px",
          marginTop: "8px",
          minWidth: "200px",
        },
      }}
    >
      {item.dropdownItems.map((dropdownItem) => (
        <MenuItem
          key={dropdownItem.name}
          component={Link}
          to={dropdownItem.action ? null : "/" + getUrlFromName(dropdownItem)}
          onClick={dropdownItem.action}
        >
          {dropdownItem.name}
          <br />
        </MenuItem>
      ))}
    </Menu>
  );
};

TopNavBar.propTypes = {
  hasBackground: PropTypes.bool,
  inputUser: PropTypes.object,
};

export default TopNavBar;
