import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignInScreen from './components/SignInScreen';
import Poems from './components/Poems';
import NotFoundPage from './components/NotFoundPage';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { CssBaseline } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: '#007777',
      light: '#a1d2d2',
    },
    secondary: {
      main: '#a1d2d2',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
        },
      },
    },
  },
});

const routes = [
  {
    path: "/",
    element: <SignInScreen />,
  },
  {
    path: "/poems",
    element: (
      <Poems />),
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.element} />
          ))}
        </Routes>
    </BrowserRouter>
  </ThemeProvider>
);
