import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "../firebase";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Alert,
  Button,
  CircularProgress,
} from "@mui/material";
import Layout from "./common/Layout";
import { Google } from "@mui/icons-material";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1, 3),
}));

function SignInScreen() {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState({
    googleLoading: false,
    buttonLoading: false,
    microsoftLoading: false,
    phoneLoading: false,
    phoneVerificationLoading: false,
  });
  const [error, setError] = useState({
    message: "",
    open: false,
  });

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (auth) => {
      if(auth) {
        window.location.href = "/poems";
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          window.location.href = "/poems";
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError({ message: error.message, open: true });
        setLoading(false);
      });
  }, []);

  const handleGoogleLogin = () => {
    setLoader({ googleLoading: true, buttonLoading: false });
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithRedirect(googleProvider)
      .then(() => {
        setLoader({ googleLoading: false, buttonLoading: false });
      })
      .catch((error) => {
        setLoader({ googleLoading: false, buttonLoading: false });
        setError({ message: error.message, open: true });
      });
  };

  return (
    <Layout>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container>
          <div id="captcha-container" style={{ display: "none" }}></div>
          <Title variant="h4">{"Login"}</Title>
          {error.open && <Alert severity="error">{error.message}</Alert>}
          {!error.open && (
            <>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<Google />}
                onClick={handleGoogleLogin}
                disabled={loader.googleLoading}
                sx={{ minWidth: "250px" }}
              >
                {loader.googleLoading ? "Loading..." : "Sign in with Google"}
              </StyledButton>
            </>
          )}
        </Container>
      )}
    </Layout>
  );
}

export default withErrorBoundary(SignInScreen, {
  fallback: <GeneralErrorPage />,
});
