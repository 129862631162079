import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyCqI2m_6UTBQ_rgL0H_TAnKKuIWPwbgK0g",
  authDomain: "passpoems.firebaseapp.com",
  projectId: "passpoems",
  storageBucket: "passpoems.appspot.com",
  messagingSenderId: "308224496891",
  appId: "1:308224496891:web:388e9ad32bea4a600acc5e"
};

firebase.initializeApp(firebaseConfig);

export const callFunction = async (functionName, data = {}, isPost = false, headers = {}) => {
  try {
    const isProd =
      process.env.NODE_ENV && process.env.NODE_ENV === "production";
    const user = firebase.auth().currentUser;
    const token = user && (await user.getIdToken());
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    const options = {
      method: isPost ? "POST" : "GET",
      headers: headers,
      body: isPost ? JSON.stringify(data) : undefined,
    };
    let url = isProd
      ? `/api/${functionName}`
      : `http://127.0.0.1:5001/passpoems/us-central1/passpoems/${functionName}`;
    if(!isPost && data && Object.keys(data).length > 0) {
      const params = Object.keys(data).map((key) => `${key}=${data[key]}`).join("&");
      url = `${url}?${params}`
    }
    const response = await fetch(url, options);
    return await response.json();
  } catch (e) {
    console.log(e)
    return false;
  }
};
