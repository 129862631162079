import { getLimitedUseToken, getToken } from "firebase/app-check";
import { useContext, useCallback } from "react";
import { AppCheckSdkContext } from "reactfire";
import { callFunction } from "./firebase";

function useGetAppCheckToken() {
  const sdk = useContext(AppCheckSdkContext);

  return useCallback(
    async function(useShortLivedToken) {
      try {
        if (!sdk) {
          return;
        }

        const { token } = await (useShortLivedToken
          ? getLimitedUseToken(sdk)
          : getToken(sdk, false));
        return token;
      } catch (e) {
        return;
      }
    },
    [sdk]
  );
}

export function useRequest() {
  const getAppCheckToken = useGetAppCheckToken();

  return useCallback(
    async function(
      path,
      body,
      isPost
    ) {
      const useAppcheck = false;
      const token = useAppcheck ? await getAppCheckToken(isPost) : "";

      const headers = {};
      if (token) {
        headers["x-firebase-appcheck"] = token;
      }

      return callFunction(path, body, isPost, headers);
    },
    [getAppCheckToken]
  );
}

export function usePost() {
  const request = useRequest();
  return function(functionName, body = {}) {
    return request(functionName, body, true);
  };
}

export function useGet() {
  const request = useRequest();
  return function(functionName, body = {}) {
    return request(functionName, body, false);
  };
}
