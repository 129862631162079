import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import image from "../assets/404.png";
import Layout from "./common/Layout";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const Image = styled("img")({
  width: "400px",
  height: "400px",
});

const Text = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#333",
});

const NotFoundPage = () => {
  return (
    <Layout disableContainer={true}>
      <Container>
        <Image src={image} alt="404" />
        <Text>Page not found!</Text>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
