/* global BigInt */
import React, { useCallback } from "react";
import {
  Box,
  CircularProgress,
  TextField, Button, Grid, Card, CardHeader, CardContent, ButtonGroup, Alert, Typography,
} from "@mui/material";
import { Autorenew, Send } from '@mui/icons-material';
import { useEffect, useState } from "react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import Layout from "./common/Layout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import { useGet, usePost } from "../request";
import useDidMountEffect from "../hooks/useDidMountEffect";

function getRandom64BitInteger() {
  // JavaScript's Number.MAX_SAFE_INTEGER is 2^53 - 1, so we generate two random 32-bit integers instead
  var firstPart = Math.floor(Math.random() * Math.pow(2, 32));
  var secondPart = Math.floor(Math.random() * Math.pow(2, 32));
  // Convert both integers to 32-bit binary strings
  var firstPartBin = firstPart.toString(2).padStart(32, '0');
  var secondPartBin = secondPart.toString(2).padStart(32, '0');
  // Concatenate the two binary strings to get a 64-bit binary string
  var fullBin = firstPartBin + secondPartBin;
  // Convert the 64-bit binary string to a decimal string
  return BigInt('0b' + fullBin).toString(10);
}

function titleCase(str) {
  return str.replaceAll('_', ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

function Poems() {
  const [integer, setInteger] = useState(getRandom64BitInteger());
  const [textBoxValue, setTextBoxValue] = useState(integer);
  const [poems, setPoems] = useState({});
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const get = useGet();
  const post = usePost();

  const getPoems = useCallback(async () => {
    setLoading(true);
    try {
      let result = await get("generate", { integer });
      if (!result) {
        setError("Something went wrong!");
      } else if (result.error) {
        setError(result.error);
      } else {
        setPoems(result);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [get, integer]);

  const ratePoem = async (id, rating) => {
    try {
      let result = await post("rate", { id, integer, rating });
      if (!result) {
        setError("Something went wrong!");
      } else if (result.error) {
        setError(result.error);
      } else {
        setRatings(prev => ({ ...prev, [id]: rating }));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const saveComment = (id, comment) => {
    setComments(prev => ({ ...prev, [id]: comment }));
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if(user) {
        await getPoems();
      } else {
        window.location.href = "/";
      }
    });
    return unsubscribe;
  }, []);

  useDidMountEffect(() => {
    getPoems();
    setTextBoxValue(integer);
  }, [integer]);

  return (
    <Layout title={"Generate Poems"} responsiveContainer>
      <Box sx={{ width: "100%", overflowX: "auto", p: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
      {loading ? (
      <CircularProgress />
    ) : error ? (
      <Alert severity="error">{error}</Alert>
    ) : (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
          <TextField value={textBoxValue} onChange={e => setTextBoxValue(e.target.value)} fullWidth />
          <Button onClick={() => setInteger(textBoxValue)}><Send /></Button>
          <Button onClick={() => setInteger(getRandom64BitInteger())}><Autorenew /></Button>
        </Box>
        <Grid container spacing={2}>
          {Object.entries(poems).map(([id, poem]) => (
            <Grid item xs={12} sm={6} md={4} key={id}>
              <Card>
                <CardHeader title={titleCase(id)} />
                <CardContent>
                  <Typography sx={{ whiteSpace: 'pre-line', mb: 2 }}>{poem}</Typography>
                  <ButtonGroup sx={{ mb: 2 }}>
                    {[1, 2, 3, 4, 5].map(rating => (
                      <Button
                        key={rating}
                        onClick={() => ratePoem(id, rating)}
                        variant={ratings[id] === rating ? 'contained' : 'outlined'}
                      >
                        {rating}
                      </Button>
                    ))}
                  </ButtonGroup>
                  <TextField
                    value={comments[id] || ''}
                    onChange={e => saveComment(id, e.target.value)}
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    )}
  </Box>
      </Box>
    </Layout>
  );
}

export default withErrorBoundary(Poems, {
  fallback: <GeneralErrorPage />,
});
